import React from "react";
import renderHTML from "../../../helpers/renderHTML";

const Header = ({ portfolio }) => {
  // const portfolioid = portfolio.wordpress_id;
  const portfolioid = 15;
  const flagsImages = require.context("../../../images/flags", true);
  const flagPath = name => flagsImages(name, true);

  return (
    <section className="item-single__header">
      <div className="item-single__half--second">
        <div className="item-single__wrapper item-single__wrapper--right">
          {portfolio.acf.featured_image !== null ? (
            <div
              className="item-single__image item-single__image--right"
              style={{
                backgroundImage: `url(${portfolio.acf.featured_image.localFile.childImageSharp.original.src})`
              }}
            />
          ) : null}
        </div>
      </div>

      <div className="item-single__main">
        <div className="container portfolio__full">
          <div
            className={`portfolio__half ${portfolioid % 2 !== 1 ? "half--first" : "half--second"}`}
          />
          <div
            className={`portfolio__half ${portfolioid % 2 !== 1 ? "half--second" : "half--first"}`}
          >
            <div className="portfolio__half__wrapper">
              <div className="item__logo item-single__logo">
                {portfolio.acf.page_logo !== null ? (
                  <img
                    className="lazyload"
                    data-src={portfolio.acf.page_logo.localFile.childImageSharp.original.src}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMsOaZdDwAFAQHms1Zh0AAAAABJRU5ErkJggg=="
                    alt={portfolio.acf.page_logo.alt_text}
                  />
                ) : null}
              </div>

              <div className="item-single__description">
                <span
                  className="description__text"
                  dangerouslySetInnerHTML={renderHTML(portfolio.acf.long_description)}
                />
              </div>

              <div className="portfolio__item__country">
                <img
                  className="portfolio__item__flag"
                  src={flagPath(portfolio.acf.country_flag.replace("images/flags", "."))}
                  alt={portfolio.acf.shop_link}
                />

                <span className="portfolio__item__link">{portfolio.acf.shop_link}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
