import React from "react";
import renderHTML from "../../../helpers/renderHTML";
import CTA from "../../CTA/CTA";

const Reference = ({ portfolio }) => {
  let featureID = 0;
  let countItem = 0;
  let countFeature = 0;

  return (
    <>
      {portfolio.acf.features.map(feature => {
        // countItem += 1;
        featureID += 1;
        countFeature = 0;
        countItem = 0;
        if (featureID === 4) {
          return (
            <div>
              <CTA />
              <div id={`feature-${featureID}`} className="features__main" key={featureID}>
                <div className="container">
                  {feature.feature.map(single => {
                    countItem += 1;

                    if (feature.feature.length >= 2) {
                      countFeature += 1;
                    }

                    if (feature.feature.length === countFeature) {
                      countFeature = feature.feature.length;
                    }

                    return (
                      <div
                        className={`feature-single ${
                          single.mobile_view !== null
                            ? "feature-single-mobileview--margin"
                            : "feature-single-normal"
                        }`}
                      >
                        <div
                          className={`feature-mockup ${
                            portfolio.acf.features.length > 2 ? "feature-mockup-second" : ""
                          } feature-mockup-${countItem % 2 === 1 ? "first" : "second"}`}
                        >
                          <img
                            src={single.photo.localFile.childImageSharp.original.src}
                            alt="desktop"
                          />
                          {single.mobile_view !== null ? (
                            <img
                              className="mobile_view"
                              src={single.mobile_view.localFile.childImageSharp.original.src}
                              alt="mobile"
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="feature-description de">
                          <div className="feature-description__field">
                            <div dangerouslySetInnerHTML={renderHTML(single.text)} />
                            <div
                              className={`feature-number ${
                                feature.feature.length < 2 ? "dnone" : ""
                              }`}
                            >
                              <div className="hex">
                                <div className="hex inner">
                                  <span className="feature-num">0{countFeature}.</span>
                                </div>
                              </div>
                            </div>

                            <div className="left-corner" />
                            <div className="right-corner" />
                          </div>
                        </div>
                        <div
                          className={`${
                            countItem % 2 === 1 ? "connect-line" : "connect-line-even"
                          } ${single.mobile_view !== null ? "connect-line-rwd" : ""}`}
                        >
                          <div className="connect-up" />
                          <div className="connect-down" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        }
        return (
          <div id={`feature-${featureID}`} className="features__main" key={featureID}>
            <div className="container">
              {feature.feature.map(single => {
                countItem += 1;

                if (feature.feature.length >= 2) {
                  countFeature += 1;
                }

                if (feature.feature.length === countFeature) {
                  countFeature = feature.feature.length;
                }

                return (
                  <div
                    className={`feature-single ${
                      single.mobile_view !== null
                        ? "feature-single-mobileview--margin"
                        : "feature-single-normal"
                    }`}
                  >
                    <div
                      className={`feature-mockup ${
                        portfolio.acf.features.length > 2 ? "feature-mockup-second" : ""
                      } feature-mockup-${countItem % 2 === 1 ? "first" : "second"}`}
                    >
                      <img
                        src={single.photo.localFile.childImageSharp.original.src}
                        alt="desktop"
                      />
                      {single.mobile_view !== null ? (
                        <img
                          className="mobile_view"
                          src={single.mobile_view.localFile.childImageSharp.original.src}
                          alt="mobile"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="feature-description de">
                      <div className="feature-description__field">
                        <div dangerouslySetInnerHTML={renderHTML(single.text)} />
                        <div
                          className={`feature-number ${feature.feature.length < 2 ? "dnone" : ""}`}
                        >
                          <div className="hex">
                            <div className="hex inner">
                              <span className="feature-num">0{countFeature}.</span>
                            </div>
                          </div>
                        </div>

                        <div className="left-corner" />
                        <div className="right-corner" />
                      </div>
                    </div>
                    <div
                      className={`${countItem % 2 === 1 ? "connect-line" : "connect-line-even"} ${
                        single.mobile_view !== null ? "connect-line-rwd" : ""
                      }`}
                    >
                      <div className="connect-up" />
                      <div className="connect-down" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Reference;
