import React from "react";
import renderHTML from "../../../helpers/renderHTML";

const Reference = ({ portfolio }) => {
  return (
    <div className="references--top">
      <div className="reference-slide">
        <div className="reference-slide__wrapper">
          <div className="top-corner" />
          <div className="bottom-corner" />
          <div className="client__image__wrapper">
            {portfolio.acf.client_photo !== null ? (
              <img
                className="client__image lazyload"
                data-src={portfolio.acf.client_photo.localFile.childImageSharp.original.src}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMsOaZdDwAFAQHms1Zh0AAAAABJRU5ErkJggg=="
                alt={portfolio.acf.client_photo.title}
              />
            ) : null}
          </div>
          <div className="reference__content">
            <div
              className="reference__content--top"
              dangerouslySetInnerHTML={renderHTML(portfolio.acf.client_reference)}
            />
            <div className="reference__content--bottom">
              <div className="client__logo__wrapper">
                {portfolio.acf.project_logo !== null ? (
                  <img
                    className="client__logo lazyload"
                    data-src={portfolio.acf.project_logo.localFile.childImageSharp.original.src}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMsOaZdDwAFAQHms1Zh0AAAAABJRU5ErkJggg=="
                    alt={portfolio.acf.project_logo.title}
                  />
                ) : null}
              </div>
              <div className="reference__person">
                <div className="client__name">{portfolio.acf.client_name}</div>
                {portfolio.acf.client_position.length > 0 ? (
                  <div className="client__position">{portfolio.acf.client_position}</div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reference;
