import React from "react";
import { FaFacebookF, FaTwitter } from "react-icons/fa";

const Share = ({ portfolio }) => {
  return (
    <section className="share">
      <div className="section__header share__header">
        <h2 className="section__title--large section__title--large__post">share this</h2>
      </div>

      <div className="container share-container">
        <a
          href={`http://www.facebook.com/sharer/sharer.php?u=https://pandagroup.co${portfolio.path}`}
          target="_blank"
          rel="noopener noreferrer"
          className="button_share button-facebook"
        >
          <div className="trapezoid-left" />
          <div className="button-content button-content-facebook">
            <span className="share-icon share-icon-facebook">
              <i className="fa fa-2x fa-facebook">
                <FaFacebookF />
              </i>
            </span>
            <span>share it on facebook</span>
          </div>

          <div className="trapezoid-right" />
        </a>

        <a
          href={`http://twitter.com/share?url=https://pandagroup.co${portfolio.path}`}
          target="_blank"
          rel="noopener noreferrer"
          className="button_share button-twitter"
        >
          <div className="trapezoid-left" />
          <div className="button-content button-content-twitter">
            <span className="share-icon share-icon-twitter">
              <i className="fa fa-2x fa-twitter">
                <FaTwitter />
              </i>
            </span>
            <span className="twitter-text">share it on twitter</span>
          </div>
          <div className="trapezoid-right" />
        </a>
      </div>
    </section>
  );
};

export default Share;
