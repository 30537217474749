import React from "react";
import { graphql } from "gatsby";

import Layout from "../Layout/Layout";

import Header from "../modules/SinglePortfolio/components/Header";
import Mockups from "../modules/SinglePortfolio/components/Mockups";
import Feature from "../modules/SinglePortfolio/components/Feature";
import Reference from "../modules/SinglePortfolio/components/Reference";
import CTA from "../modules/CTA/CTA";

import Share from "../modules/Share/Share";

// import PortfolioForm from "../modules/Portfolio/components/PortfolioForm";
import SEO from "../Layout/Seo";

const PageSinglePortfolioTemplate = props => {
  const portfolio = props.data.wordpressWpPortfolio;
  let itemID = 0;
  const cleanDescription = portfolio.acf.short_text.replace(/<\/?[^>]+(>|$)/g, "");

  return (
    <Layout>
      <SEO
        title={portfolio.yoast.title ? portfolio.yoast.title : portfolio.title}
        keywords={portfolio.yoast.metakeywords}
        description={portfolio.yoast.metadesc ? portfolio.yoast.metadesc : cleanDescription}
      />
      <Header portfolio={portfolio} />
      <Mockups portfolio={portfolio} />
      <CTA />
      {portfolio.acf.features.length > 0 ? (
        <>
          <div className="section__header portfolio-homepage__header">
            <div className="section__title--small">what we&apos;ve done</div>
            <h2 className="section__title--large">Features</h2>
          </div>
          <div className="features-list">
            {portfolio.acf.features.map(feature => {
              itemID += 1;
              return (
                <a className="features-list__item" href={`#feature-${itemID}`} key={itemID}>
                  <div className="corner-left" />
                  <div className="feature__text">{feature.feature_name}</div>
                  <div className="corner-right" />
                </a>
              );
            })}
          </div>
        </>
      ) : null}

      <section className="features">
        <Feature portfolio={portfolio} />

        <div className="section__header references-homepage__header">
          <div className="section__title--small">see our happy clients</div>
          <h2 className="section__title--large">references</h2>
        </div>
        <div className="container">
          <Reference portfolio={portfolio} />
        </div>
      </section>
      <CTA />
      <Share portfolio={portfolio} />
    </Layout>
  );
};

export default PageSinglePortfolioTemplate;

export const pageQuery = graphql`
  query currentPortfolioPageQuery($id: String!) {
    wordpressWpPortfolio(id: { eq: $id }) {
      acf {
        page_logo {
          source_url
          title
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        client_name
        client_reference
        client_position
        client_photo {
          source_url
          title
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        country_flag
        shop_link
        short_title
        short_text
        what_wewe_done
        long_description
        client_name
        client_position
        client_reference
        desktop_mockup {
          source_url
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        featured_logo
        featured_image {
          id
          title
          source_url
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        features {
          feature_name
          feature {
            text
            photo {
              source_url
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            mobile_view {
              source_url
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
        mobile_mockup {
          source_url
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        project_logo {
          source_url
          title
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
      path
      title
      yoast {
        title
        metakeywords
        metadesc
      }
      wordpress_id
    }
  }
`;
