import React from "react";

const Mockups = ({ portfolio }) => {
  return (
    <section className="item-single__mockups">
      <div className="item-single__mockups__half--first">
        <div className="item-single__mockups__wrapper item-single__mockups__wrapper--left">
          {portfolio.acf.desktop_mockup !== null ? (
            <div
              className="item-single__mockups__image item-single__mockups__image--left"
              style={{
                backgroundImage: `url(${portfolio.acf.desktop_mockup.localFile.childImageSharp.original.src})`
              }}
            />
          ) : null}
        </div>
      </div>

      <div className="item-single__mockups__half--second">
        <div className="item-single__mockups__wrapper item-single__mockups__wrapper--right">
          {portfolio.acf.mobile_mockup !== null ? (
            <div
              className="item-single__mockups__image item-single__mockups__image--right"
              style={{
                backgroundImage: `url(${portfolio.acf.mobile_mockup.localFile.childImageSharp.original.src})`
              }}
            />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Mockups;
